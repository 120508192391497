import { PLATFORM } from "aurelia-pal";

export const Routes = [
  /* profile pages */
  {
    route: ["edit", "" ], //By default
    name: "edit_profile",
    moduleId: PLATFORM.moduleName("./edit_profile/edit_profile"),
    auth: true,
    nav: false,
    settings: { roles: ["user", "admin", "free"] },
    title: "Edit your profile",
  },
  {
    route: ["my_qr_code"],
    name: "my_qr_code",
    moduleId: PLATFORM.moduleName("./my_qr_code/my_qr_code"),
    auth: true,
    nav: false,
    settings: { roles: ["user", "admin", "free"] },
    title: "Your QR-Code",
  },
  {
    route: ["choose_currency_format"],
    name: "choose_currency_format",
    moduleId: PLATFORM.moduleName("./choose_currency_format/choose_currency_format"),
    auth: true,
    nav: true,
    settings: { roles: ["user", "admin", "free"] },
    title: "profile.choose_currency_format.tabtitle",
  },  
  {
    route: ["subscription"],
    name: "subscription",
    moduleId: PLATFORM.moduleName("./subscription/subscription"),
    auth: true,
    nav: true,
    settings: { roles: ["user", "admin", "free"] },
    title: "Subscription",
  },
  {
    route: ["change_password"],
    name: "change_password",
    moduleId: PLATFORM.moduleName("../auth/change_password/change_password"),
    auth: true,
    nav: true,
    settings: { roles: ["user", "admin", "free"] },
    title: "Change your password",
  },
  {
    route: ["contact_us"],
    name: "contact_us",
    moduleId: PLATFORM.moduleName("./contact_us/contact_us"),
    auth: true,
    nav: true,
    settings: { roles: ["user", "admin", "free"] },
    title: "Contact us",
  },
  {
    route: ["choose_language"],
    name: "choose_language",
    moduleId: PLATFORM.moduleName("./choose_language/choose_language"),
    auth: true,
    nav: true,
    settings: { roles: ["user", "admin", "free"] },
    title: "Choose language",
  },
  {
    route: ["conditions"],
    name: "conditions",
    moduleId: PLATFORM.moduleName("../legal/conditions/conditions"),
    auth: true,
    nav: true,
    settings: { roles: ["user", "admin", "free"] },
    title: "Read Conditions",
  },
  {
    route: ["notifications"],
    name: "notifications",
    moduleId: PLATFORM.moduleName("./notifications/notifications"),
    auth: true,
    nav: true,
    settings: { roles: ["user", "admin", "free"] },
    title: "Notifications",
  },
];

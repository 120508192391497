import { Router } from "aurelia-router";
import { autoinject } from "aurelia-dependency-injection";
import { checkResponseStatus } from "http_clients/checkResponseStatus";
import { WalletsManager } from "services/WalletManager.service";
import { SingletonService } from "singleton";
import localforage from "localforage";
import { UsersHttpClients } from "http_clients/UsersHttpClients";
import { YapilyHttpClient } from "http_clients/YapilyHttpClient";
import { User } from "components/models/UserModel";
import { PaymailWalletClient } from "http_clients/PaymailWalletClient";

@autoinject
export class profile_delete {
  private isDisabled: boolean = true;
  private me: User;

  constructor(
    private singleton: SingletonService,
    private walletsManager: WalletsManager,
    private router: Router,
    private userHttpClient: UsersHttpClients,
    private yapilyHttpClients: YapilyHttpClient,
    private paymailWalletHttpClient: PaymailWalletClient
  ) {
    this.buttonDisabler();
  }

  buttonDisabler() {
    setTimeout(() => {
      this.isDisabled = false;
    }, 5000);
  }

  async deleteAccount() {
    try {
      await this.removeWallets();
      return;
      let request = await this.userHttpClient.fetch("/users/self", {
        method: "DELETE",
      });
      let is200OK = await checkResponseStatus(request);
      let reponse = await is200OK.json();
      alert("YOUR ACCOUNT HAS BEEN DELETED ");
    } catch (e) {
      console.log(e);
    }
  }

  async removeWallets() {
    try {
      const confirmDelete = confirm(
        "Are you sure you want to delete your wallets?"
      );

      if (confirmDelete) {
        this.me = this.singleton.getMe();
        const walletKey = "wallets_" + this.me._id;
        const wallet = await localforage.getItem(walletKey);

        if (typeof wallet === "string") {
          const parsedWallet = JSON.parse(wallet);
          const keys = Object.keys(parsedWallet);

          if (keys.length > 0) {
            const walletName = keys[0];
            await this.deleteWalletFromDB(walletName);
          }
        }

        this.singleton.setCryptoWalletSelected(false);
        this.singleton.setCurrentCryptoWalletIndex(-1);

        // Remove "isCryptoWalletSelected" and "currentCryptoWalletIndex"
        await localStorage.removeItem("isCryptoWalletSelected");
        await localStorage.removeItem("currentCryptoWalletIndex");
        await localforage.removeItem("processedTx");

        // Get all the keys that start with "./.txs" or "./.idx"
        let txsKeys = Object.keys(localStorage).filter(
          (key) => key.startsWith("./.txs") || key.startsWith("./idx")
        );
        // Loop through the keys and remove them
        for (let key of txsKeys) {
          await localStorage.removeItem(key);
        }

        alert("Your wallets have been deleted");
      }
    } catch (e) {
      console.log(e);
    }
  }

  async deleteWalletFromDB(walletName) {
    let request = await this.paymailWalletHttpClient.fetch(
      "/wallet/" + walletName,
      {
        method: "DELETE",
      }
    );
    let is200OK = await checkResponseStatus(request);
    let reponse = is200OK.json();
  }
}

// Module
var code = `<template>
  <require from="./profile_delete.scss"></require>
  <section id="profile_delete" class="flex-column">
    <div id="header" class="flex-row"></div>
    <h2 t="profile_delete.title"></h2>
    <div class="flex-grow"></div>
    <i class="fas fa-exclamation-triangle"></i>
    <div class="flex-grow"></div>
    <div class="delete flex-column">
      <p t="profile_delete.will_be_deleted_forever"></p>
      <p t="profile_delete.there_is_no_going_back"></p>
    </div>
    <div class="flex-grow"></div>
    <button
      id="profile.delete.button"
      class="btn btn-primary"
      click.delegate="deleteAccount()"
      t="profile_delete.delete"
      disabled.bind="isDisabled"
    ></button>
    <div class="spacer-default"></div>
  </section>
</template>
`;
// Exports
export default code;
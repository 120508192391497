import { autoinject, computedFrom, inject } from "aurelia-framework";
import { AuthService } from "aurelia-auth";
import {
  Router,
  RouterConfiguration,
  NavigationInstruction,
  Next,
  PipelineStep,
  PipelineResult,
  RouterEvent,
  Redirect,
} from "aurelia-router";
import { EventAggregator } from "aurelia-event-aggregator";
import { PLATFORM } from "aurelia-pal";
import { HttpClient } from "aurelia-fetch-client";
import { I18N } from "aurelia-i18n";
import { User } from "components/models/UserModel";
import { SingletonService } from "singleton";
import { MenuItem } from "components/models/MenuItem";
import { Routes } from "./routes";
import { Store, connectTo } from "aurelia-store";
import { State } from "state";

@connectTo()
@autoinject()
export class Profile {
  private me: User;
  private hasTx: boolean;
  private admin: boolean = false;
  private isProfilePage = true;
  private currentInstruction;
  private navEvent;
  private codePin: boolean = true;
  private pageTitle: string = "";
  private subtitle: string = "";

  constructor(
    private router: Router,
    private i18n: I18N,
    private authService: AuthService,
    private ea: EventAggregator,
    private singleton: SingletonService
  ) {
    this.getMe();
    this.ea.subscribe(
      RouterEvent.Complete,
      (event: {
        instruction: NavigationInstruction;
        result: PipelineResult;
      }) => {
        this.currentInstruction = event.instruction;
        this.isProfilePage =
          event.instruction.fragment === "/profile" ? true : false;

          this.setPageTitle();
      }
    );
  }

  bind() {
    
  }

  attached() {
    this.navEvent = this.ea.subscribe(
      RouterEvent.Success,
      (event: {
        instruction: NavigationInstruction;
        result: PipelineResult;
      }) => {
        this.currentInstruction = event.instruction;
        this.setPageTitle();
      }
    );

    this.ea.subscribe("router:navigation:success", () => {
      this.getMe();
    });
  }

  async getMe() {
    this.me = await this.authService.getMe();
  }

  public configureRouter(
    config: RouterConfiguration,
    router: Router
  ): Promise<void> | PromiseLike<void> | void {
    config.title = "MyBanka";
    config.map(Routes);
    config.options.pushState = true;
    config.options.root = "/";
    this.router = router;
  }

  setPageTitle() {
    if(this.currentInstruction.fragment === "/profile") {
      this.pageTitle = this.i18n.tr("profile.profile1");
      this.subtitle = this.i18n.tr("profile.subtitle.profile");
    }
    else if (this.currentInstruction.fragment === "/profile/edit") {
      this.pageTitle = this.i18n.tr("profile.profile4");
      this.subtitle = this.i18n.tr("profile.subtitle.edit_profile");
    }
    else if (this.currentInstruction.fragment === "/profile/my_qr_code") {
      this.pageTitle = this.i18n.tr("profile.profile11");
      this.subtitle = this.i18n.tr("profile.subtitle.my_qr_code");
    }
    else if (this.currentInstruction.fragment === "/profile/subscription") {
      this.pageTitle = this.i18n.tr("profile.profile3");
      this.subtitle = this.i18n.tr("profile.subtitle.subscription");
    }
    else if (this.currentInstruction.fragment === "/profile/change_password") {
      this.pageTitle = this.i18n.tr("profile.profile2");
      this.subtitle = this.i18n.tr("profile.subtitle.change_password");
    }
    else if (this.currentInstruction.fragment === "/profile/contact_us") {
      this.pageTitle = this.i18n.tr("profile.profile7");
      this.subtitle = this.i18n.tr("profile.subtitle.contact_us");
    }
    else if (this.currentInstruction.fragment === "/profile/choose_language") {
      this.pageTitle = this.i18n.tr("profile.profile8");
      this.subtitle = this.i18n.tr("profile.subtitle.choose_language");
    }
    else if (this.currentInstruction.fragment === "/profile/choose_currency_format") {
      this.pageTitle = this.i18n.tr("profile.choose_currency_format.title");
      this.subtitle = this.i18n.tr("profile.subtitle.choose_currency_format");
    }
    else if (this.currentInstruction.fragment === "/profile/conditions") {
      this.pageTitle = this.i18n.tr("profile.profile10");
      this.subtitle = this.i18n.tr("profile.subtitle.conditions");
    }
  }

  goToAdmin() {
    if (this.me.role == "admin") {
      this.router.navigateToRoute("adminhome");
    } else {
      console.error("YOU ARE NOT AN ADMIN NICE TRY");
    }
  }

  goToQrCode() {
    this.router.navigateToRoute("my_qr_code", { friendId: this.me._id });
  }
  goToHome() {
    this.router.navigateToRoute("home");
  }

  goToSecurity() {
    this.router.navigateToRoute("security");
  }

  goToNotifications() {
    this.router.navigateToRoute("notification");
  }

  goToChangePassword() {
    this.router.navigateToRoute("change_password");
  }

  goToChooseLanguage() {
    this.router.navigateToRoute("choose_language");
  }

  goToChooseCurrencyFormat() {
    this.router.navigateToRoute("choose_currency_format");
  }

  goToDeleteCards() {
    this.router.navigateToRoute("account_overview");
  }

  goToMyAccounts() {
    this.router.navigateToRoute("my_accounts");
  }

  goToprofile() {
    this.router.navigateToRoute("edit_profile");
  }

  goToConditions() {
    this.router.navigateToRoute("conditions");
  }

  goToContactUs() {
    this.router.navigateToRoute("contact_us");
  }

  goToSubscriptions() {
    this.router.navigateToRoute("subscription");
  }

  goToSettings() {
    this.router.navigateToRoute("settings");
  }

  goToStats() {
    this.router.navigateToRoute("camembert");
  }
  goToFriends() {
    this.router.navigateToRoute("contacts");
  }
  goToRemember() {
    this.router.navigateToRoute("remember");
  }
  goToPinCodeCreate() {
    this.router.navigateToRoute("pin_code_create");
  }
  goToPinCodeActual() {
    this.router.navigateToRoute("pin_code_actual");
  }
  deleteAccount() {
    this.router.navigateToRoute("profile_delete");
  }

  goBack() {
    this.router.navigateBack();
  }
}

// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/img/arrow-right.svg", import.meta.url);
// Module
var code = `<template>
  <require from="./profile.scss"></require>
  <require
    from="../../components/header-titles/header-title-profile/header-title-profile"
  ></require>

  <section id="profile" class="flex-column">
    <header-title-profile
      title.bind="pageTitle"
      subtitle.bind="subtitle"
      menu-items.bind="listMenuItems"
      is-editing.two-way="isEditing"
    >
    </header-title-profile>
    </div>
    <p class="admin" if.bind="admin" click.delegate="goToAdmin()">Admin</p>
    <div class="flex-row dual">
      <div
        class="left flex-column"
        class.bind="isProfilePage ? '': 'hidden_on_mobile'"
      >
        <div class="photo flex-column">
          <img
            src="\${ state.me.urlPic }"
            click.delegate="goToprofile()"
            t="[title]profile.profile4"
          />
          <p class="name" click.delegate="goToprofile()">
            \${state.me.displayName}
          </p>
          <p class="email" click.delegate="goToprofile()">\${state.me.email}</p>
        </div>
        <div class="features_component flex-row">
          <div class="flex-column container safari_only">
            <div
              class="profile_card black flex-row"
              click.delegate="router.navigateToRoute('edit_profile')"
              class.bind="(currentInstruction.fragment === '/profile' || currentInstruction.fragment === '/profile/edit') ? 'active' : ''"
            >
              <i class="fas fa-user"></i>
              <p t="profile.profile4"></p>
              <p class="end_row_clickeable">
                <img src="${___HTML_LOADER_IMPORT_0___}" />
              </p>
            </div>
            <div
              class="profile_card black flex-row"
              click.delegate="goToSubscriptions()"
              class.bind="currentInstruction.fragment == '/profile/subscription' ? 'active' : ''"
            >
              <i class="fas fa-user"></i>
              <p t="profile.profile3"></p>
              <p class="end_row_clickeable">
                <img src="${___HTML_LOADER_IMPORT_0___}" />
              </p>
            </div>
            <div
              class="profile_card black flex-row"
              click.delegate="goToChangePassword()"
              class.bind="currentInstruction.fragment == '/profile/change_password' ? 'active' : ''"
            >
              <i class="fas fa-key"></i>
              <p t="profile.profile2"></p>
              <p class="end_row_clickeable">
                <img src="${___HTML_LOADER_IMPORT_0___}" />
              </p>
            </div>
            <div
              class="profile_card black flex-row"
              click.delegate="goToContactUs()"
              class.bind="currentInstruction.fragment == '/profile/contact_us' ? 'active' : ''"
            >
              <i class="fas fa-question"></i>
              <p t="profile.profile7"></p>
              <p class="end_row_clickeable">
                <img src="${___HTML_LOADER_IMPORT_0___}" />
              </p>
            </div>
            <div
              class="profile_card black flex-row"
              click.delegate="goToChooseLanguage()"
              class.bind="currentInstruction.fragment == '/profile/choose_language' ? 'active' : ''"
            >
              <i class="fas fa-language"></i>
              <p t="profile.profile8"></p>
              <p class="end_row_clickeable">
                <img src="${___HTML_LOADER_IMPORT_0___}" />
              </p>
            </div>
            <div
              class="profile_card black flex-row"
              click.delegate="goToConditions()"
              class.bind="currentInstruction.fragment == '/profile/conditions' ? 'active' : ''"
            >
              <i class="fas fa-book"></i>
              <p t="profile.profile10"></p>
              <p class="end_row_clickeable">
                <img src="${___HTML_LOADER_IMPORT_0___}" />
              </p>
            </div>
            <div
              class="profile_card black flex-row"
              if.bind="state.me.hasPin"
              click.delegate="goToPinCodeActual()"
              class.bind="currentInstruction.fragment == '/profile/pin_code_actual' ? 'active' : ''"
            >
              <i class="fas fa-fingerprint"></i>
              <p class="profile10" t="profile.profile_pincode"></p>
              <p class="end_row_clickeable">
                <img src="${___HTML_LOADER_IMPORT_0___}" />
              </p>
            </div>
            <div
              class="profile_card black flex-row"
              if.bind="!state.me.hasPin"
              click.delegate="goToPinCodeCreate()"
              class.bind="currentInstruction.fragment == '/profile/pin_code_create' ? 'active' : ''"
            >
              <i class="fas fa-fingerprint"></i>
              <p class="profile10" t="profile.profile_pincode"></p>
              <p class="end_row_clickeable">
                <img src="${___HTML_LOADER_IMPORT_0___}" />
              </p>
            </div>
            <div
              class="profile_card black flex-row"
              if.bind="state.me.role == 'admin'"
              click.delegate="goToAdmin()"
            >
              <i class="fas fa-gavel"></i>
              <p class="red">Admin</p>
              <p class="end_row_clickeable">
                <img src="${___HTML_LOADER_IMPORT_0___}" />
              </p>
            </div>
            <div
              class="profile_card black flex-row"
              click.delegate="authService.logout()"
            >
              <i class="fas fa-power-off"></i>
              <p class="red" t="profile.profile9"></p>
              <p class="end_row_clickeable">
                <img src="${___HTML_LOADER_IMPORT_0___}" />
              </p>
            </div>
            <div class="flex-grow"></div>
          </div>
        </div>
      </div>
      <div class="right" class.bind="isProfilePage ? 'hidden_on_mobile': ''">
        <p>\${router.currentInstruction.params.route}</p>
        <p>\${router.currentInstruction.params.name}</p>
        <router-view swap-order="after"></router-view>
      </div>
    </div>
  </section>
</template>
`;
// Exports
export default code;